<template>
  <!-- Start list content -->
  <div class="content">
    <div class="top">
      <div class="top-left" >
        <router-link class="return" @click.native="usePageMemory.savePage(1)" :to="{ name: 'home', query: { page: homeMarkedPage } }">
          <img src="@/assets/images/icons/i-chevron-left.svg" alt="chevron icon">
          <span>Retour à la liste</span>
        </router-link>
        <div>
          <img src="@/assets/images/document.svg" alt="documents">
          <h2>Document</h2>
        </div>
      </div>
        <div class="kpi" v-if="!tenderStatsLoading">
          <h4>Temps passé sur l'AO</h4>
          <div class="time-spent">
            <p>{{ secondsToReadableDuration(tenderStats.timeSpent, 'short') }}</p>
          </div>
        </div>
    </div>
    <div class="insights">
      <h3 class="insights__title">{{tenderStats.name}}</h3>
      <div class="insights__container">
        <div class="insights__item">
          <p>Nombre de lignes traitées</p>
          <p class="insights__infos">{{treatedLines}} sur {{tenderStats.total || 0}} ({{(treatedLines / (tenderStats.total || 1) * 100).toFixed(2)}}%)</p>
        </div>

        <div class="insights__item">
          <p>Matchs exacts sur les lignes validées</p>
          <p class="insights__infos">{{tenderStats['SUGGESTED'] || 0}} sur {{tenderStats['VALIDATED'] || 0}} ({{tenderStats['SUGGESTED'] ? (tenderStats['SUGGESTED'] / tenderStats['VALIDATED'] * 100).toFixed(2) : 0}}%)</p>
        </div>

        <div class="insights__item">
          <p>Matchs alternatifs sur les lignes validées</p>
          <p class="insights__infos">{{tenderStats['ALTERNATIVE'] || 0}} sur {{tenderStats['VALIDATED'] || 0}} ({{tenderStats['ALTERNATIVE'] ? (tenderStats['ALTERNATIVE'] / tenderStats['VALIDATED'] * 100).toFixed(2) : 0}}%)</p>
        </div>

        <div class="insights__item">
          <p>Autres matchs sur les lignes validées</p>
          <p class="insights__infos">{{tenderStats['MANUAL'] || 0}} sur {{tenderStats['VALIDATED'] || 0}} ({{tenderStats['MANUAL'] ? (tenderStats['MANUAL'] / tenderStats['VALIDATED'] * 100).toFixed(2) : 0}}%)</p>
        </div>
      </div>
    </div>

    <div class="list">
      <h3>Liste des demandes</h3>

      <div class="list__top">
        <div>
          <h4>Aperçu du traitement du document</h4>
          <div class="status">
            <div class="status__item"><p>{{tenderStats['PENDING'] || 0}}/{{tenderStats.total}} - En attente ({{tenderStats['PENDING'] ? (tenderStats['PENDING'] / tenderStats.total * 100).toFixed(2) : 0}}%)</p></div>
            <div class="status__item status__item--validated"><p>{{tenderStats['VALIDATED'] || 0}}/{{tenderStats.total}} - Validées ({{tenderStats['VALIDATED'] ? (tenderStats['VALIDATED'] / tenderStats.total * 100).toFixed(2) : 0}}%) </p></div>
            <div class="status__item status__item--missing"><p>{{tenderStats['HELP'] || 0}}/{{tenderStats.total}} -  En manque d’infos ({{tenderStats['HELP'] ? (tenderStats['HELP'] / tenderStats.total * 100).toFixed(2) : 0}}%) </p></div>
            <div class="status__item status__item--seen"><p>{{tenderStats['SEEN'] || 0}}/{{tenderStats.total}} - Vues ({{tenderStats['SEEN'] ? (tenderStats['SEEN'] / tenderStats.total * 100).toFixed(2) : 0}}%)</p></div>
            <div class="status__item status__item--noScope"><p>{{tenderStats['EXCLUDED'] || 0}}/{{tenderStats.total}} - Hors scope ({{tenderStats['EXCLUDED'] ? (tenderStats['EXCLUDED'] / tenderStats.total * 100).toFixed(2) : 0}}%)</p></div>
          </div>
        </div>
        <router-link v-if="demands.quickSelect" :to="seeDemand(tenderId, demands.quickSelect, sort)" >
          <button class="btn btn--blue"><img class="btn__icon" src="@/assets/images/icons/i-play.svg" alt="play icon">Lancer la sélection rapide</button>
        </router-link>
      </div>

      <div class="list__table">
        <div class="list__head">
          <div class="f-1">
                <p>
                  Numéro
                  <img
                    v-if="sort['kind'] === 'order'"
                    @click="activeSort('order')"
                    src="@/assets/images/icons/i-arrow-up.svg"
                    class="icon-arrow"
                    v-bind:class="({ 'inverted': sort['SORT'] === 'ASC' })"
                    alt="sortings arrows icon Numero"
                  >
                  <img
                    v-else
                    @click="activeSort('order')"
                    src="@/assets/images/icons/i-arrows-up-down.svg"
                    class="icon-arrow"
                    alt="sortings arrows icon Numero"
                  >
                </p>
          </div>
          <div class="f-4">
            <p>
              Demande
              <img
                v-if="sort['kind'] === 'alphabetical'"
                @click="activeSort('alphabetical')"
                src="@/assets/images/icons/i-arrow-up.svg"
                class="icon-arrow"
                v-bind:class="({ 'inverted': sort['SORT'] === 'ASC' })"
                alt="sortings arrows icon Demand"
              >
              <img
                v-else
                @click="activeSort('alphabetical')"
                src="@/assets/images/icons/i-arrows-up-down.svg"
                class="icon-arrow"
                alt="sortings arrows icon Demand"
              >
            </p>
          </div>
          <p class="f-6">Selection</p>
          <div class="f-3 filters">
            <p>Etat</p>
            <button @click.stop="toggleFilters" class="btn--icon" title="Filtrer les états">
              <img src="@/assets/images/icons/i-filter.svg" alt="filter icon">
            </button>

            <ul
              v-if="displayFilters"
              v-click-outside="() => displayFilters = false"
              class="filter__choice">
              <li>Tout sélectionner <input @click="activeFilter('all')" type="checkbox" id="all" :checked="areAllChecked" ><label for="all" class="checkbox"></label></li>
              <li>En attente <input @click="activeFilter('PENDING')" type="checkbox" :checked="filters['PENDING']"  id="waiting"><label for="waiting" class="checkbox"></label></li>
              <li>Validé <input @click="activeFilter('VALIDATED')" type="checkbox" :checked="filters['VALIDATED']"  id="validated"><label for="validated" class="checkbox"></label></li>
              <li>Demande d'infos <input @click="activeFilter('HELP')" type="checkbox" :checked="filters['HELP']"  id="missing"><label for="missing" class="checkbox"></label></li>
              <li>Vu <input @click="activeFilter('SEEN')" type="checkbox" :checked="filters['SEEN']"  id="seen"><label for="seen" class="checkbox"></label></li>
              <li>Hors scope <input @click="activeFilter('EXCLUDED')" type="checkbox" :checked="filters['EXCLUDED']"  id="noScope"><label for="noScope" class="checkbox"></label></li>
            </ul>
          </div>
        </div>

        <!-- Start of list line -->
        <div v-for="demand in demands.list" :key="demand.id" class="list__bordered">
          <div class="list__line" v-if="demand.status !== 'ERROR'" >
            <div class="f-1"><p class="list__title">{{demand.number}}</p></div>
            <div class="f-4"><p class="list__title">{{demand.content}}</p></div>
            <div class="f-6" v-if="demand.selectedProduct" >
              <img :src="demand.selectedProduct.imageUrl" class="list__img" />
              <p class="list__ref f-1">Ref: <span>{{demand.selectedProduct.sku}}</span></p>
              <p class="f-4">{{demand.selectedProduct.name}}</p>
            </div>
            <div v-else class="error-container f-6">
              <p>
                <svg class="error-svg" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96019 0.693993 8.98492 0.5 8 0.5ZM8.75 11.75H7.25V10.25H8.75V11.75ZM8.75 8.75H7.25V4.25H8.75V8.75Z" fill="#F55F44"/>
                </svg>
                Aucun produit ne correspond
              </p>
            </div>
            <div class="f-2 status_button">
              <ChoiceList
                :disableValidated="demand.selectedProduct === null"
                :updateStatus="updateStatus"
                :tenderId="tenderId"
                :demand="demand" />
            </div>
            <!-- <div class="f-1"></div> -->
            <div class="f-1">
              <router-link :to="seeDemand(tenderId, demand.id, sort)" class="btn btn--white">Voir</router-link>
            </div>
          </div>
        </div>
        <!-- End of list line -->
      </div>

      <div class="pagination">
        <p class="pagination__results">
          Résultats {{ (page - 1) * resultsPerPage }} - {{ (page - 1) * resultsPerPage + demands.length }} sur {{ demandCount }}
        </p>
        <div class="pagination__bottom">
          <div class="pagination__container">
            <button
              v-if="this.page > 1"
              @click="goToPage(page - 1)" class="pagination__item">
              <img src="@/assets/images/icons/i-chevron-left-black.svg" alt="chevron left icon">
            </button>
            <button
              v-for="pageToDisplay in pages" :key="pageToDisplay"
              @click="goToPage(pageToDisplay)"
              :class="({ 'pagination__item--active': page === pageToDisplay })"
              class="pagination__item ">
              {{ pageToDisplay }}
            </button>
            <button
              v-if="this.page < lastPage"
              @click="goToPage(page + 1)"
              class="pagination__item">
              <img src="@/assets/images/icons/i-chevron-right-black.svg" alt="chevron right icon">
            </button>
          </div>
          <div class="pagination__goto">
            <p>Aller à la page</p>
            <input
              min="1"
              :max="lastPage"
              v-model.lazy.number="page"
              @change="goToPage(page)"
              type="number">
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End list content -->
</template>

<script>
import { ref, computed } from '@vue/composition-api';
import ChoiceList from '../components/ChoiceList';
import usePaginate from '@/hooks/paginate';
import usePageMemory from '@/hooks/pageMemory';
import useStopwatch from '@/hooks/stopwatch';
import { secondsToReadableDuration } from '@/filters';
import { PAGE_STORAGE_KEY } from '@/constants/storage';

export default {
  name: 'List',
  components: { ChoiceList },
  data() {
    return {
      displayFilters: false,
      homeMarkedPage: localStorage.getItem(PAGE_STORAGE_KEY) || 1,
    };
  },
  setup(props, context) {
    const tenderId = computed(() => context.root.$route.params.tender);
    /** Filters */
    const filters = ref({
      PENDING: true,
      VALIDATED: true,
      SEEN: true,
      HELP: true,
      EXCLUDED: true,
    });

    function setFiltersTo(value) {
      filters.value = {
        PENDING: value,
        VALIDATED: value,
        SEEN: value,
        HELP: value,
        EXCLUDED: value,
      }
    }

    const areAllChecked = computed(() => {
      return !Object.values(filters.value).some(filter => !filter)
    });

    function activeFilter(filter) {
      if (filter === 'all') {
        setFiltersTo(!areAllChecked.value);
      } else {
        filters.value[filter] = !filters.value[filter];
      }
      goToPage(1);
    }

    function formatDemand(demand) {
      const { products } = demand;
      const selectedProduct = demand.selectedProduct !== null ? products.find(product => product.id === demand.selectedProduct.id) : products.find(product => product.status === 'SUGGESTED')
      return {
        ...demand,
        selectedProduct: selectedProduct || null,
      };
    }

    function updateStatus(demandToUpdate) {
      demands.value.list.splice(
        demands.value.list.findIndex(({ id }) => id === demandToUpdate.id),
        1,
        formatDemand(demandToUpdate)
      );
      getTenderStats();
    }

    /** Sort */
    const sort = ref({
      SORT: context.root.$route.query['SORT'] || 'ASC',
      SORT_FIELD: context.root.$route.query['SORT_FIELD'] || 'demand.number',
      kind: 'order',
    });

    function activeSort(sortKind) {
      switch(sortKind) {
        case 'alphabetical':
          sort.value['SORT'] =
            (sortKind === sort.value['kind'] && sort.value['SORT'] === 'ASC')
              ? 'DESC'
              : 'ASC'
          sort.value['SORT_FIELD'] = 'demand.content'
          sort.value['kind'] = 'alphabetical'
          break
        case 'order':
          sort.value['SORT'] =
            (sortKind === sort.value['kind'] && sort.value['SORT'] === 'ASC')
              ? 'DESC'
              : 'ASC'
          sort.value['SORT_FIELD'] = 'demand.number'
          sort.value['kind'] = 'order'
          break
        default:
          sort.value['SORT'] = 'ASC'
          sort.value['SORT_FIELD'] = 'demand.number'
          sort.value['kind'] = 'order'
      }

      goToPage(1);
    }

    /** Pagination */
    const resultsPerPage = 25;
    const {
      data: demands,
      currentPage: page,
      total: demandCount,
      loading: demandLoading,
      lastPage, pages,
      goToPage, previous, next,
    } = usePaginate({
      url: `/tenders/${tenderId.value}`,
      currentPage: parseInt(context.root.$route.query.page) || usePageMemory.pageSaved.value,
      options: computed(() => ({
        ...filters.value,
        ...sort.value,
      })),
      updateFn: (page, options) => {
        usePageMemory.savePage(page.value)
        context.root.$router.push({
          query: {
            page: page.value,
            SORT: options.sort.value['SORT'],
            SORT_FIELD: options.sort.value['SORT_FIELD'],
          },
        }).catch(() => {});
      },
      resultsPerPage,
      dataTransformer: response => {
        return {
          list: response.demands.map(formatDemand),
          quickSelect: response.quickSelect,
        }
      },
      totalPageTransformer: response => Math.ceil(response.count / resultsPerPage),
      totalTransformer: response => response.count
    });
    /** End pagination */

    /** Stats */
    const tenderStatsLoading = ref(false);
    const tenderStats = ref({});
    const treatedLines = computed(() => (tenderStats.value['VALIDATED'] || 0) + (tenderStats.value['HELP'] || 0) + (tenderStats.value['EXCLUDED'] || 0))
    function getTenderStats() {
      tenderStatsLoading.value = true
      return context.root.$axios.get(`/tenders/${tenderId.value}/stats`)
        .then(response => {
          tenderStats.value = response.data
          tenderStatsLoading.value = false
        })
    }
    useStopwatch(tenderId.value, 5000, context.root.$store.getters['auth/userInfo']['username'], (updatedTender) => {
      tenderStats.value.timeSpent = updatedTender.timeSpent;
    });

    /** End stats */

    /** Init */
    goToPage(page.value);
    getTenderStats();

    return {
      tenderId,
      tenderStats,
      tenderStatsLoading,
      treatedLines,

      sort,
      activeSort,

      demands,
      demandLoading,
      page,
      goToPage,
      previous,
      next,
      lastPage,
      resultsPerPage,
      pages,
      filters,
      demandCount,
      areAllChecked,
      activeFilter,
      updateStatus,
      secondsToReadableDuration,
      usePageMemory
    }
  },
  methods: {
    seeDemand(tender, demand, sort) {
      return {
        name: 'demand',
        params: { tender, demand },
        query: {
          SORT_FIELD: sort['SORT_FIELD'],
          SORT: sort['SORT'],
        }
      };
    },
    toggleFilters() {
      this.displayFilters = !this.displayFilters
    },
  },
};
</script>

<style scoped src="../assets/css/list.css"></style>
<style lang="scss" scoped>
  .return {
    width: max-content;
  }

  .icon-arrow {
    transition: 0.2s;
  }

  .inverted {
    transform: rotate(180deg);
  }

  .kpi {
    background-color: #fff;
    border-radius: 6px;
    padding: 12px;
    margin: 3px 0;
    min-width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    > div.time-spent {
      display: flex;
      align-items: center;

      p {
        font-size: 20px;
        margin: 12px 0;
        font-weight: 600;
        color: #3d3d3e;
      }
    }

    h4 {
      font-size: 16px;
      color: #3d3d3e;
      font-weight: 600;
    }
  }

  .btn--white {
    text-decoration: none;
  }

  .list__line .error-svg {
    width: 16px;
    height: 16px;
    margin-right: 10px !important;
  }

  .error-container {
    justify-content: flex-start;
  }

  .error-container p {
    font-weight: normal;
    font-style: italic;
    font-size: 14px;
    color: rgba(0, 0, 0, .75);
  }

  .list__line * {
    display: flex;
    align-items: center;
    justify-content: left;
  }
</style>
