import { ref } from '@vue/composition-api';

export const pageSaved = ref(1);

export function savePage(toSave) {
    pageSaved.value = toSave;
}

export default {
    pageSaved,
    savePage,
}
